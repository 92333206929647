// Use npm-provided htmx
window.htmx = require('htmx.org');

// Timeout for any requests issued by htmx
htmx.config.timeout = 5000;

// Include cookies
htmx.config.withCredentials = true;

// Install service worker
if ("serviceworker" in navigator) {
  navigator.serviceWorker.register(
    new URL('service-worker.js', import.meta.url),
    { type: 'module' }
  );
}

